import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section``

const LegalPageContent = ({ content }) => {
  if (!content) return null

  return (
    <Container>
      <Grid>
        <Row mq={{ xsmallDown: { pullY: 6 } }}>
          <Cell
            pushX={4}
            size={14}
            mq={{ smallDown: { pushX: 1, size: 21 } }}
            className="legal-page-content"
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Cell>
        </Row>
      </Grid>
    </Container>
  )
}

LegalPageContent.propTypes = {
  content: PropTypes.string,
}

export default LegalPageContent
