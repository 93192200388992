import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import LegalPageContent from "../components/legal-page-content"

const LegalPageTemplate = ({ data, pageContext }) => {
  const { metaDefaults, metaWpGlobal, metaWpLegal, siteMessage, hero, page } =
    data
  const { translations, menus } = pageContext

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      siteMessage={siteMessage}
    >
      <MetaWp
        page={metaWpLegal}
        global={metaWpGlobal}
        defaults={metaDefaults}
      />

      <Hero data={hero} />

      <LegalPageContent content={page.content} />
    </Layout>
  )
}

export default LegalPageTemplate

LegalPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpLegalFragment
    ...SiteMessageFragment

    ...HeroLegalFragment

    page: wordpressWpLegal(id: { eq: $id }) {
      content
    }
  }
`
